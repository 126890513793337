import { css } from '@emotion/css';
import {
  colors,
  FlexLayout,
  layoutHelpers,
  ParagraphText,
  SectionHeading,
  StandardButton,
  VerticalSpace,
} from '@imtbl/design-system';
import { StyledNextLink } from 'components/DsConnectorButtons';
import * as deprecation from 'utils/deprecation';
import ROUTES from 'utils/router';

export default function DeprecationPage() {
  const { shutDownDate, toFormattedDate } = deprecation;
  const pageTitle = 'IMX Marketplace has shut down';

  return (
    <FlexLayout
      flexGrow={1}
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
      className={css`
        max-width: 976px;
      `}
      paddingLeft={layoutHelpers.gridUnits(4)}
      paddingRight={layoutHelpers.gridUnits(4)}
      marginLeft="auto"
      marginRight="auto"
    >
      <VerticalSpace both="medium">
        <SectionHeading
          testId="pageTitle"
          fillColor={colors.light[300]}
          fontSize="2x-large"
        >
          {pageTitle}
        </SectionHeading>
      </VerticalSpace>
      <ParagraphText
        textAlign="left"
        fontSize="normal"
        fillColor={colors.light[300]}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        <p>
          On <b>{toFormattedDate(shutDownDate)}</b>
          <i>
            ,{' '}
            <span style={{ color: colors.blue[100] }}>
              market.immutable.com
            </span>
          </i>{' '}
          was closed;
        </p>
        <p>
          <b>Why?</b>
        </p>
        The Immutable Marketplace was built as a showcase of features that are
        available to developers via the Immutable SDK. Since then, some amazing
        projects have been created and have grown massively. As a company,
        rather than compete, we would prefer to support these marketplaces and
        see the Immutable Ecosystem grow. You can check out some of these
        excellent projects via the link below.
        <p>
          <b>What about my assets & listings?</b>
        </p>
        Your assets live on the blockchain, and therefore you own them! Your
        assets are linked to your wallet address, which stays with you
        regardless of the marketplace, game or application that you are using.
        The Immutable Marketplace uses the{' '}
        <StyledNextLink
          href="https://www.immutable.com/products/orderbook"
          fontWeight="bold"
        >
          Immutable Global Orderbook
        </StyledNextLink>
        , meaning that any maketplace utlising the orderbook shows the same
        listings for buying/selling. All you need to do is connect your wallet
        and you will see your existing listings in any marketplace.
        <p>
          <b>What now?</b>
        </p>
        Click the link below to explore some of the Marketplaces in our
        ecosystem!.
        <br />
      </ParagraphText>

      <VerticalSpace top="3x-large">
        <FlexLayout
          width="100%"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StandardButton
            onClick={() => {
              window.location.href = ROUTES.marketplacesPath();
            }}
            buttonKind="ultimate-cta"
            testId="walletFunctionalityBtn--connectWallet"
          >
            Take me to marketplaces
          </StandardButton>
        </FlexLayout>
      </VerticalSpace>
    </FlexLayout>
  );
}
