/**
 * IMX Marketplace Deprecation
 * https://immutable.atlassian.net/wiki/x/FoA3lw
 */

// Deprecated by August 13, 2024
export const noticeDate = new Date('2024-08-13T00:00:00Z');
// Shutdown by October 10, 2024
export const shutDownDate = new Date('2024-10-10T00:00:00Z');

export const toFormattedDate = (date: Date) =>
  date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
